"use strict";

function MeebGalleryOverlay(modalId) {
    this.overlay = false;
    this.timer = false;
    this.modalId = modalId;
    this.disabled = false;

    this.onOverlayShowHandler = false;
    this.onOverlayHideHandler = false;

    var overlay = this;
    $(document).off('overlay');
    $(document).on('dragover.overlay', function (e) {
        if (!overlay.containsFiles(e)) {
            return true;
        }

        if (overlay.disabled)
            return false;

        overlay.show();
    });

    $(document).on('dragleave.overlay', function (e) {
        if (overlay.disabled)
            return false;

        if (overlay.overlay) {
            overlay.hide(true);
        }
    });
};

MeebGalleryOverlay.prototype.containsFiles = function(event) {
    event.dataTransfer = event.originalEvent.dataTransfer;
    if (event.dataTransfer.types) {
        for (var i = 0; i < event.dataTransfer.types.length; i++) {
            if (event.dataTransfer.types[i] == "Files") {
                return true;
            }
        }
    }

    return false;
}

MeebGalleryOverlay.prototype.disable = function() {
    this.disabled = true;
};

MeebGalleryOverlay.prototype.enable = function() {
    this.disabled = false;
};

MeebGalleryOverlay.prototype.onOverlayShow = function(func) {
    this.onOverlayShowHandler = func;
};

MeebGalleryOverlay.prototype.onOverlayHide = function(func) {
    this.onOverlayHideHandler = func;
};

MeebGalleryOverlay.prototype.isOverlayActive = function() {
    // If we have no modal id we need to check any existing fileupload gallery
    if (this.modalId === false) {
        var target = $('.multifile-gallery');
        if (target.length > 0) {
            if (typeof target.modal === "function" && target.is(':visible')) {
                return false;
            }
        }

        return true;
    } else {
        return $(this.modalId).is(':visible');
    }
};

MeebGalleryOverlay.prototype.generateOverlayHtml = function() {
    if (this.modalId == false) {
        return this.generateComplexOverlayHtml();
    } else {
        return this.generateSimpleOverlayHtml();
    }
};

MeebGalleryOverlay.prototype.generateSimpleOverlayHtml = function() {
    return '<div class="overlay" style="z-index: 999999999999999;">' +
        '    <div class="center">' +
        '        <p>Přetáhněte obrázek</p>' +
        '        <i class="fa fa-upload fa-xl"></i>' +
        '    </div>' +
        '</div>';
};

MeebGalleryOverlay.prototype.generateComplexOverlayHtml = function() {
    return '<div class="overlay" style="z-index: 999999999999999;">' +
        '    <div class="center">' +
        '        <div class="step step_0" style="display: block">' +
        '           <p>Přetáhněte obrázek</p>' +
        '           <i class="fa fa-upload fa-xl"></i>' +
        '       </div>' +
        '       <div class="step step_1">' +
        '           <p>Nahrávám soubory</p>' +
        '           <i class="fa fa-hourglass fa-x1 faa-spin animated"></i>' +
        '       </div>' +
        '       <div class="step step_2">' +
        '           <p>Soubory byly úspěšně uloženy</p>' +
        '           <i class="fa fa-thumbs-up fa-x1 faa-bounce animated"></i>' +
        '       </div>' +
        '    </div>' +
        '</div>';
};

MeebGalleryOverlay.prototype.show = function() {
    if (this.isOverlayActive()) {
        if (!this.overlay) {
            $('body').prepend(this.generateOverlayHtml());

            this.overlay = $('.overlay');
            this.overlay.fadeIn('fast');

            if (typeof this.onOverlayShowHandler === "function" )
                this.onOverlayShowHandler();
        }

        clearTimeout(this.dragTimer);
    }
};

MeebGalleryOverlay.prototype.hide = function(delayed) {
    if (delayed) {
        if (this.dragTimer) {
            clearTimeout(this.dragTimer);
        }

        var self = this;
        this.dragTimer = setTimeout(function () {
            self.fadeOut();
        }, 100);
        //this.dragTimer = setTimeout(this.fadeOut, 100);
    } else {
        this.fadeOut();
    }
};

MeebGalleryOverlay.prototype.fadeOut = function() {
    if (this.overlay) {
        var self = this;
        if (typeof this.onOverlayHideHandler === 'function')
            this.onOverlayHideHandler();

        this.overlay.fadeOut('fast', function () {
            if (typeof self.overlay.remove === "function")
                self.overlay.remove();

            self.overlay = false;
            self.dragTimer = false;
        });
    }
};

MeebGalleryOverlay.prototype.destroy = function() {
    $(document).off('.overlay');
};

function MeebGallery(di, snippetId, modalId, basePath, isSingleImageGallery) {
    this.timer = false;
    this.serviceHandler = false;
    this.summernote = false;

    this.di = di;
    this.modalId = modalId;
    this.basePath = basePath;
    this.snippet = $('#' + snippetId);

    this.overlay = new MeebGalleryOverlay(modalId);
    this.request = false;

    if (isSingleImageGallery) {
        this.initSingleImageGallery();
        this.imageField = this.loadImageField();

        const self = this;
        const info = $(self.modalId).find('.file-update-info');
        if (info.length > 0 && self.imageField) {
            const id = info.data('auto-select-id');
            const name = info.data('auto-select-name');

            const inputName = self.imageField.data('field-name');
            const idField = $('input[name="' + inputName + '"]');

            idField.val(id);
            self.imageField.val(name);
            this.setupFieldImage(this.imageField);
            //self.imageField.trigger();

            $(self.modalId).modal('hide');
        }
    }

    this.initAutoSubmit();
    this.initSearch();
    this.initSummernoteIntegration();
    this.initFileupload();

    this.overlay.disable();

    const self = this;
    $(this.modalId).on('show.bs.modal', function () {
        self.overlay.enable();
        GlobalUploadManager.getInstance(null).overlay.disable();
    });

    $(this.modalId).on('hide.bs.modal', function() {
        self.overlay.disable();
        GlobalUploadManager.getInstance(null).overlay.enable();
    });
}

MeebGallery.prototype.saveImageField = function(elem) {
    if (typeof(localStorage) === "undefined") return;

    localStorage.setItem(this.modalId, elem.attr('id'));
}

MeebGallery.prototype.loadImageField = function() {
    if (typeof(localStorage) === "undefined") return false;

    const id = localStorage.getItem(this.modalId);
    return $('#' + id);
}

MeebGallery.prototype.clearImageField = function() {
    if (typeof(localStorage) === "undefined") return;

    localStorage.removeItem(this.modalId);
}

MeebGallery.prototype.initSingleImageGallery = function() {
    var gallery = this;
    this.snippet.find('input[data-gallery-modal]').each(function () {
        $(this).data({ setupFieldImage: function() { gallery.initSingleImageGallery() } });
        if ($(this).val().length > 0) {
            gallery.setupFieldImage($(this));
        }
    });

    var fieldName = false;
    var fileInput = false;

    $(this.modalId).on('hide.bs.modal', function () {
        fieldName = false;
        fileInput = false;

        $(this).removeClass('summernote-mode');

        gallery.clearImageField();
        gallery.getGalleryFileInput().removeAttr('accept');
    });

    this.snippet.off('.gallery');
    $(gallery.modalId).on('click.gallery', 'a[data-file]', function () {
        if (fieldName) {
            $('input[name="' + fieldName + '"]').val($(this).data('file'));
        }

        fileInput = fileInput ? fileInput : gallery.imageField;
        if (fileInput) {
            fileInput.val($(this).data('file-name'));
            fileInput.change();
        }

        $(gallery.modalId).modal('hide');
    });

    this.snippet.on('click.gallery', 'input[data-gallery-modal]', function (e) {
        e.stopPropagation();
        e.preventDefault();

        fieldName = $(this).data('field-name');
        fileInput = $(this);

        gallery.setFileSelect(fileInput);
        gallery.updateAllowedFileTypes(fileInput);

        $(gallery.modalId).modal('show');
    });

    this.snippet.on('change.gallery', 'input[data-gallery-modal]', function () {
        gallery.setupFieldImage($(this));
    });

    this.snippet.on('click.gallery', '.remove-image', function () {
        var parent = $(this).closest('.form-group');
        var elem = parent.find('input[data-gallery-modal]');
        elem.val('');

        parent.find('.img-wrapper').remove();

        var field = elem.data('field-name');
        $('input[name="' + field + '"]').val('null');
    });
};

MeebGallery.prototype.getFileSelectField = function() {
    return $(this.modalId).find('select[name="file_type"]');
}

MeebGallery.prototype.setFileSelect = function(field) {
    this.saveImageField(field);

    var val = field.data('file-type');

    var select = this.getFileSelectField();
    var selected = select.find('option[selected]').attr('value');

    if (val != selected) {
        select.find('option').removeAttr('selected');
        select.find('option[value="' + val + '"]').attr('selected', 'selected');
        select.trigger('change');
    }
}

MeebGallery.prototype.getGalleryFileInput = function() {
    return $(this.modalId).find('input[type="file"]');
};

MeebGallery.prototype.updateAllowedFileTypes = function(field) {
    var filetypes = field.data('accept-files');

    var fileInput = this.getGalleryFileInput();
    fileInput.attr('accept', filetypes);
};

MeebGallery.prototype.initSummernoteIntegration = function() {
    var gallery = this;

    this.serviceHandler = this.di.getService('snippetManager').on('after-update', function () {
        gallery.updatePasteIconVisibility(false);
    });

    $(this.modalId).on('show.bs.modal', function () {
        //$('modalId').draggable({ handle: '.modal-header'});
        gallery.updatePasteIconVisibility(true);
    });

    $(this.modalId).on('click.gallery', 'button[data-img]', function () {
        if (gallery.summernote) {
            var url = $(this).data('img');
            var fileName = $(this).data('img-name');
            var fileType = $(this).data('type');
            if (typeof fileType === 'undefined') {
                fileType = 'image';
            }

            $(gallery.modalId).modal('hide');

            gallery.summernote.summernote('restoreRange');
            gallery.summernote.summernote('focus');

            if (fileType === 'image') {
                gallery.summernote.summernote('insertImage', url, fileName);
            } else {
                gallery.summernote.summernote('createLink', {
                    text: fileName,
                    url: url,
                    isNewWindow: true
                });
            }
        }
    });

    $(document).on('focus.gallery', 'input', function() {
        gallery.summernote = false;
    });

    $('.wysiwyg-editor').on('summernote.focus.gallery', function () {
        gallery.summernote = $(this);
    });
}

MeebGallery.prototype.initFileupload = function() {
    var elem = $(this.modalId).find('#fileupload');
    var url = elem.data('url');
    var gallery = this;

    $(elem).fileupload({
        url: url,
        dataType: 'json',
        drop: function(e) {
            if (!$(gallery.modalId).is(':visible')) {
                e.preventDefault();
            }
        },
        start: function() {
            gallery.overlay.hide(false);
            gallery.resetProgressBar();
        },
        done: function (e, data) {
            gallery.resetProgressBar();
            updateSnippets(data.result);
            gallery.updatePasteIconVisibility(false);
        },
        progressall: function (e, data) {
            var progress = parseInt((data.loaded / data.total) * 100, 10);
            gallery.updateProgressBar(progress);
        }
        }).prop('disabled', !$.support.fileInput).parent().addClass($.support.fileInput ? undefined : 'disabled');
};

MeebGallery.prototype.initSearch = function() {
    var gallery = this;
    this.snippet.on('keyup.gallery', '.search', function () {
        var url = $(this).data('url');
        var data = $(this).closest('form').serializeArray();

        gallery.createTimer(function() {
            /*if (request) {
                request.abort();
            }*/

            gallery.request = $.post(url, data, function(data) {
                gallery.request = false;
                updateSnippets(data);
                gallery.updatePasteIconVisibility(false);
            });
        });
    });
}

MeebGallery.prototype.initAutoSubmit = function() {
    this.snippet.on('change.gallery', '.autosubmit', function () {
        $(this).closest('form').find('input[type="submit"]').click();
    });
}

MeebGallery.prototype.destroy = function() {
    if (this.serviceHandler)
        this.di.getService('snippetManager').off('after-update', this.serviceHandler);

    $(this.modalId).off('.gallery');
    this.snippet.off('.gallery');
    $(this.modalId).off('hide.bs.modal');
    $(this.modalId).off('show.bs.modal');

    var target = $('#fileupload');
    if (typeof target.fileupload === 'function' && target.hasClass('initialised')) {
        $('#fileupload').fileupload('destroy');
    }
};

MeebGallery.prototype.setupFieldImage = function(elem) {
    console.log('setup of field image');
    console.log(elem);

    // Clear old wrapper first
    elem.parent().find('.img-wrapper').remove();

    var imageName = elem.val();
    var imageWrapper = $('<div class="img-wrapper"></div>');

    if (!elem.is('[required]')) {
        var clearButton = $('<a class="remove-image" href="#"><i class="fa fa-2x fa-trash"></i></a>');
        imageWrapper.append(clearButton);
    }

    var image = $('<img class="img-thumb" />').attr('src', this.basePath + '/userdata/upload/thumb_' + imageName);
    imageWrapper.append(image);

    elem.parent().append(imageWrapper);
    this.imageField = elem;
};

MeebGallery.prototype.createTimer = function(func) {
    this.stopTimer();
    this.timer = setTimeout(func, 300);
};

MeebGallery.prototype.stopTimer = function() {
    if (this.timer) {
        clearTimeout(this.timer);
        this.timer = false;
    }
};

MeebGallery.prototype.updateProgressBar = function(percentage) {
    $('#progress .progress-bar').css('width', percentage + '%');
};

MeebGallery.prototype.resetProgressBar = function() {
    this.updateProgressBar(0);
};

MeebGallery.prototype.updatePasteIconVisibility = function(saveRange) {
    if (this.summernote) {
        $('button[data-img]').parent().show();
        if (saveRange)
            this.summernote.summernote('saveRange');
    } else {
        $('button[data-img]').parent().hide();
    }
};